import { render, staticRenderFns } from "./_projectId.vue?vue&type=template&id=562324fc"
import script from "./_projectId.vue?vue&type=script&setup=true&lang=ts"
export * from "./_projectId.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./_projectId.vue?vue&type=style&index=0&id=562324fc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesNotificationCard: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/notification-card/notification-card.vue').default,OffersTitle: require('/vercel/path0/components/OffersTitle.vue').default,Recap: require('/vercel/path0/components/Recap.vue').default,AdvantagesPush: require('/vercel/path0/components/AdvantagesPush.vue').default,MoleculesLoader: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/loader/loader.vue').default,OffersLegalNotice: require('/vercel/path0/components/OffersLegalNotice.vue').default,StickyBottomOffer: require('/vercel/path0/components/StickyBottomOffer.vue').default,DrawerDocuments: require('/vercel/path0/components/DrawerDocuments.vue').default})
